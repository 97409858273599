// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*SCSS mixins for devices*/
.faqPageContainer .TileManager .ContentTile {
  min-height: 100vh;
}
@media (max-width: 767px) {
  .faqPageContainer .TileManager .ContentTile {
    min-height: 80vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/_styles.scss","webpack://./src/components/Faq.scss"],"names":[],"mappings":"AAAA,0BAAA;ACKQ;EACI,iBAAA;AAHZ;ADEE;ECAM;IAIQ,gBAAA;EAFd;AACF","sourcesContent":["/*SCSS mixins for devices*/\n\n$mobile-max-width: 767px;\n\n@mixin mobile {\n  @media (max-width: $mobile-max-width) {\n    @content;\n  }\n}\n\n// Tablet screens (768px to 1024px)\n@mixin tablet {\n  @media (min-width: 768px) and (max-width: 1024px) {\n    @content;\n  }\n}\n\n// Desktop screens (1025px to 1439px)\n@mixin desktop {\n  @media (min-width: 1025px) and (max-width: 1439px) {\n    @content;\n  }\n}\n\n// Big monitor screens (1440px and up)\n@mixin big-monitor {\n  @media (min-width: 1440px) {\n    @content;\n  }\n}","@use '../styles';\n\n.faqPageContainer{\n    .TileManager {\n\n        .ContentTile {\n            min-height: 100vh;\n\n            @include styles.mobile {\n                min-height: 80vh;\n            }\n        }\n       \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
