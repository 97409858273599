import React from 'react';

import './App.css';

import CookieContext from './CookieContext';

// Define the type for your state
interface AppState {
  cookieData: { [key: string]: string | null },
  isDarkMode: boolean
}

interface AppProps {
  children?: React.ReactNode;
};

// Utility functions
function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return decodeURIComponent(match[2]);
  return null;
}

function setCookie(name: string, value: string, days: number): void {
  document.cookie = `${name}=${value}; max-age=${days * 24 * 60 * 60}; path=/;`;
}

function removeCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    // Initialize state with cookie data
    
    this.updateStateFromCookie();
  }

  updateStateFromCookie = () => {
    this.state = {
      cookieData: {
        user: getCookie('name'),
        picture: getCookie('picture'),
        apiEndpoint: getCookie('api_endpoint'),
        email: getCookie('email'),
        playgroundApiKey: getCookie('playground_api_key'),
        givenName: getCookie('givenname'),
        // Add more cookies as needed
      },
      isDarkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    };
  }

  handleSystemThemeChange = (e: MediaQueryListEvent) => {
    this.setState({ isDarkMode: e.matches });
  };

  // Logout function to clear cookies and update state
  logout = () => {
    removeCookie('api_endpoint');
    removeCookie('email');
    removeCookie('givenname');
    removeCookie('name');
    removeCookie('nickname');
    removeCookie('picture');
    removeCookie('playground_api_key');
    removeCookie('session');
    removeCookie('surname');

    // Update state to reflect logged-out status
    this.setState({
      cookieData: {
        user: null,
        picture: null,
        email: null,
        playgroundApiKey: null,
        givenName: null,
        // Reset other cookies as needed
      },
    });

    const local_dev = process.env.LOCAL_DEV === 'true';
    if (!local_dev)
    {
        // If we're not doing local dev, we need to redirect to the logout page so that Cloud-CDN-Cookie can be deleted
        const logoutUrl = `${window.location.protocol}//${window.location.host}/secured/logout.html`;
        // Redirect to the secured content so logout can happen
        window.location.href = logoutUrl;
    }
  };

  login = () => {

      if (process.env.LOCAL_DEV === 'true') {
        setCookie('name', 'John Doe', 1);
        setCookie('email', "john.doe@text2motion.ai", 1);
        setCookie('givenname', "John", 1);
        if (process.env.LOCAL_DEV_PLAYGROUND_API_KEY)
          setCookie('playground_api_key', process.env.LOCAL_DEV_PLAYGROUND_API_KEY, 1);
        if (process.env.API_ENDPOINT)
          setCookie("api_endpoint", process.env.API_ENDPOINT, 1);
        else
          setCookie("api_endpoint", "https://gamma.api.text2motion.ai", 1);
        
        this.updateStateFromCookie();
        this.setState(this.state);
        
        window.location.reload(); // Needed to ensure the state is updated
      }
      else
      {
        // Redirect.html already knows to redirect back to the root of the current host address, so we don't pass anything
        // This is a fix for an issue with __Secure-GCP_IAP_QUERY_PARAMS being set, which prevents redirect from working.
        const redirectUrl = `${window.location.protocol}//${window.location.host}/secured/redirect.html`;
        // Redirect to the secured content so login can happen
        window.location.href = redirectUrl;
      }
  }

  isLoggedIn = () => {
    this.updateStateFromCookie();
    return (this.context != null && this.state != null && 
      (process.env.LOCAL_DEV == 'true' ? // If we're doing local dev
        (getCookie("name") != null) : // We're logged in if we have a name
        (getCookie("playground_api_key") != null))); // Otherwise we need a playground API key
  }

  componentDidMount() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', this.handleSystemThemeChange);

    document.documentElement.setAttribute('data-theme', this.state.isDarkMode ? 'dark' : 'light');
  }

  componentWillUnmount() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.removeEventListener('change', this.handleSystemThemeChange);
  }

  componentDidUpdate() {
    document.documentElement.setAttribute('data-theme', this.state.isDarkMode ? 'dark' : 'light');
  }

  render() {
    const { children } = this.props;
    const { cookieData } = this.state;
    // const style: React.CSSProperties = {
    //   pointerEvents: 'auto',
    // }
    return (
        <CookieContext.Provider value={{ cookieData , login: this.login, logout: this.logout, isLoggedIn: this.isLoggedIn }}>
          <div>
            {children}
          </div>
        </CookieContext.Provider>
    );
  }
}

export default App;