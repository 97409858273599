// HamburgerMenu.tsx
import React, { Component, ReactNode } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './HamburgerMenu.scss';

interface HamburgerMenuProps {
  children ?: ReactNode;
};

interface HamburgerMenuState {
  isOpen: boolean;
}

class HamburgerMenu extends Component<HamburgerMenuProps, HamburgerMenuState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { isOpen } = this.state;
    const { children } = this.props;
    return (
      <div onClick={this.toggleMenu} className='hamburger-container'>
        <div style={{ cursor: 'pointer',height: '100%' ,top: '50%' }}>
          <div className={`hamburger ${isOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={`menu ${isOpen ? 'show' : ''}`}>
          <ul>
            {React.Children.map(children, (child: ReactNode, index: number) => {
              return (
                <li key={index}>
                  {child}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default HamburgerMenu;
