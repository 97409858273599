import React, { Component } from 'react';

interface InitialsProps {
  name: string | null;
}

interface InitialsState {
  initials: string | null;
}

class Initials extends Component<InitialsProps, InitialsState> {
  constructor(props: InitialsProps) {
    super(props);
    this.state = {
      initials: this.calculateInitials(props.name)
    };
  }

  calculateInitials(name: string | null): string | null {
    if (!name) {
        return '';
    }
    // Split the name by spaces and filter out empty strings
    const nameParts = name.split(' ').filter(part => part.length > 0);
    // Take the first letter of each part and join them to create initials
    const initials = nameParts.map(part => part[0].toUpperCase()).join('');
    return initials;
  }

  render() {
    return (
      <>
        {this.state.initials}
      </>
    );
  }
}

export default Initials;
