import React, { Component, CSSProperties } from "react";
import { TileManager } from "./TileManager";
import ContentTile from "./ContentTile";

import "./ContactForm.scss";
import CustomButton from "./CustomButton";

interface ContactFormProps {
    name: string;
    title?: string;
    subTitle?: string;
    roles: {};
    accessKey: string;
    redirectUrl?: string;
};

export class ContactForm extends Component<ContactFormProps> {
    constructor(props: ContactFormProps) {
        super(props);
    }

    renderRoles(roles: { [key: string]: string }) {
        const result = [];
        for (let role in roles) {
            result.push(
                <option key={role} value={role}>{roles[role]}</option>
            );
        }
        return result;
    }

    render() {

        const botCheckStyle: CSSProperties = {
            display: "none"
        };

        const singleLineInputStyle: CSSProperties = {
            resize: "none",
        };
        
        let { name, accessKey, title, subTitle, roles, redirectUrl } = this.props;
        roles = roles ?? new Map<string, string>();

        return (
            <div className="contactFormContainer">
                <TileManager
                    orientation="vertical"
                    width="100vw"
                    height="auto"
                    background="transparent"
                    root={true}
                >
                    <TileManager orientation="horizontal" height="4rem" />
                    <TileManager
                        orientation="vertical"
                        width="100vw"
                        height="auto"
                    >
                        <ContentTile width="100%" height="100%">
                            <h1><em><u>{ title ?? "Contact us" }</u></em></h1>
                            <h2>{ subTitle ?? "Please fill in your query below and we will do our best to get back to you as soon as possible!"}</h2>
                            <form action="https://api.web3forms.com/submit" method="POST" id="form">
                                <input type="hidden" name="access_key" value={accessKey} />
                                <input type="hidden" name="redirect" value={redirectUrl ?? window.location.protocol + "//" + window.location.host} />
                                <input type="hidden" name="subject" value={name}></input>
                                <input type="checkbox" name="botcheck" id="" style={botCheckStyle} />
                                <textarea rows={1} style={singleLineInputStyle} wrap="off" name="name" autoComplete="name" id="name" placeholder="Your name*" required/>
                                <textarea rows={1} style={singleLineInputStyle} name="email" autoComplete="email" id="email" placeholder="Your email*" required/>
                                <textarea rows={1} style={singleLineInputStyle} name="company" autoComplete="company" id="company" placeholder="Your organization*" required/>
                                <select name="role" id="role" required>
                                        { this.renderRoles(roles) }
                                </select>                                
                                <textarea rows={4} name="message" id="message" autoComplete="off" placeholder="Enter your query here*"></textarea>
                                <br/>
                                <div className="samerow">
                                    <button type="submit">Submit</button>
                                    <CustomButton label="Go back" link=".." />
                                </div>
                                <div className="h-captcha" data-captcha="true"></div>
                            </form>

                            <script src="https://web3forms.com/client/script.js" async defer></script>
                        </ContentTile>
                    </TileManager>
                </TileManager>
            </div>
        )
    }
}