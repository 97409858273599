import React, { ReactNode } from 'react';
import { Tile, TileProps } from './Tile';

import './TileManager.scss';

export interface TileManagerProps extends TileProps {
    id?: string;
    title?: string;
    orientation: 'horizontal' | 'vertical'; // Orientation property
    alignment?: 'inherit' | 'start' | 'center' | 'end'; // Alignment property
    root?: boolean; // Root property
}

export class TileManager extends Tile<TileManagerProps> {
    getPadding() {
        let result = 'unset';
        switch (this.props.orientation) {
            case 'horizontal':
                result = '0 var(--padding)';
            case 'vertical':
                result = 'var(--padding) 0';
            default:
                result = 'none';
        }

        if (this.props.title) {
            result = '0 var(--padding)';
        }

        return result;
    }

    render() {
        const {
            id,
            children,
            orientation, debug,
            alignment,
            width, height,
            background,
            root } = this.props;

        type flexDirection = 'row' | 'column';

        const backgroundRgb = getComputedStyle(document.documentElement).getPropertyValue(`color-bg`).trim();
        const backgroundColor = this.getBackgroundColor(background, backgroundRgb);

        const containerStyle: React.CSSProperties = {
            display: 'flex',
            flexDirection: orientation === 'vertical' ? 'column' : 'row' as flexDirection, // Adjust layout based on orientation
            justifyContent: alignment ?? 'inherit', // Adjust alignment based on alignment prop
            backdropFilter: background == "blurred" || background == undefined ? "var(--blur-background)" : 'none',
            WebkitBackdropFilter: background == "blurred" || background == undefined ? "var(--blur-background)" : 'none',
            background: backgroundColor,
            padding: root ? "unset" : this.getPadding(),
            width: width ?? 'unset',
            height: height ?? 'unset'
        };

        if ((orientation == "horizontal") && !height) {
            containerStyle.flexGrow = 1;
        }

        if ((orientation == "vertical") && !width) {
            containerStyle.flexGrow = 1;
        }

        let titleStyle = { ...containerStyle, flexDirection: 'row', width: "auto" } as React.CSSProperties;
        return (
            <>
                {this.props.title ? <span className="tile-manager-title" style={titleStyle}>{this.props.title}</span> : null}
                <div id={id ?? this.props.title} className={this.getClassName()} style={containerStyle}>
                    {children}
                </div>
            </>
        );
    }
}