import React from 'react';

import './Footer.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ThemeAwareComponent } from './Themeable';
import NavLinks from './NavLinks';

interface FooterProps {
}

export class Footer extends ThemeAwareComponent<FooterProps> {
    render() {
        let branch = __BRANCH__;
        let tag = __TAG__;
        let commit = __COMMIT__;
        let link = `https://github.com/text2motion/t2m-website/commit/${commit}`;
        return (
            <footer className="Footer">
              <div className="Footer-content">
                <nav className="Footer-nav">
                  <NavLinks className="NavLinks left"/>
                  <div>
                    <a href="/privacy.html" target='_blank' className="Footer-nav-item">Privacy Policy</a>
                    <a href="/tos.html" target="_blank" className="Footer-nav-item">Terms of Service</a>
                  </div>
                </nav>
              </div>
              <div className="Footer-content">
                <div className='Footer-social'>
                  <a href="https://www.youtube.com/@text2motion" target="_blank"><i className="fa-brands fa-youtube"></i></a>
                  <a href="https://www.facebook.com/text2motion" target="_blank"><i className="fa-brands fa-facebook"></i></a>
                  <a href="https://www.instagram.com/text2motion" target="_blank"><i className="fa-brands fa-instagram"></i></a>
                  <a href="https://x.com/text2motion" target="_blank"><i className="fa-brands fa-x-twitter"></i></a>
                  <a href="https://www.linkedin.com/company/text2motion" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
                  <a href="https://github.com/text2motion" target="_blank"><i className="fa-brands fa-github"></i></a>
                </div>
                <div>© 2024 Text2Motion. All rights reserved.</div>
              </div>
              <div className="Footer-gitInfo">
                <div>{branch} - {tag} @ <a href={link} target='_blank'>{commit}</a></div>
              </div>
          </footer>
        );
    }
}