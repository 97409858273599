import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import { TileManager } from "./TileManager";
import ContentTile from "./ContentTile";
import VideoTile from "./VideoTile";

import "./Home.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { PlaygroundTile } from "./PlaygroundTile";
import TileSet from "./TileSet";
import CustomButton from "./CustomButton";
import ThemeImage from "./ThemeImage";
import CarouselTile from './CarouselTile';
import TypeIt from 'typeit-react';
import { Textillate } from 'textillate-react';
import AnimatedText, { InEffect, OutEffect } from './AnimatedText';

import { Link } from "react-router-dom";
import BlankTile from "./BlankTile";

interface HomeProps {
};

export class Home extends Component<HomeProps> {

  constructor(props: HomeProps) {
    super(props);
  }

  render() {
    return (
      <TileManager
        orientation="vertical"
        width="100vw"
        height="auto"
        background="transparent"
        root={true}
      >
        <TileManager orientation="horizontal" height="4rem" />
        <TileManager orientation="horizontal" height="auto">

          <CarouselTile width="100%" height="42em" autoPlay={true} autoPlaySpeed={10000}>
            <ContentTile width='100%' height='40em'>
              <h1>
                <TypeIt getBeforeInit={(instance) => {
                  instance
                    .options({ loop: true })
                    .type("Text, ")
                    .pause(1000)
                    .type("to <em>motion</em>. ")
                    .pause(1000)
                    .options({ speed: 50 })
                    .type("It's that simple.")
                    .pause(1500)
                    .go();

                  return instance;
                }} />
              </h1>
              <hr />
              <p>
                Our platform allows <i>anyone</i>, from an indie developer to a professional animator, to
                accelerate the process of bringing a character to life in seconds.
              </p>
              <br />
              <CustomButton label="Try it" link="/#Playground" />
            </ContentTile>
            <ContentTile width='100%' height='40em'>
              <h1>
              <TypeIt
                getBeforeInit={(instance) => {
                  // Type the initial text
                  instance
                    // .options({ speed: 100 })
                    .type("Your animation ") // "Your animation" typed
                    .pause(500) // Pause briefly

                    // Type the suggestion "copilot" inside <samp> and apply gray color
                    .options({ speed: 0 }) // Set typing speed to instantaneous
                    .type('<em style="color: var(--color-fg-opacity);">copilot.</em>') // Display suggestion in gray using <em> with inline style
                    .move(-8) // Move the cursor back to the end of "copilot"
                    .pause(1000) // Pause briefly
                    .move(8)

                    // Simulate pressing 'Tab', re-render "copilot" as <em> (highlighted)
                    .delete(8) // Delete "copilot" part
                    // .pause(500) // Pause briefly
                    .type('<em>copilot.</em>') // Re-type "copilot" inside <em> for emphasis
                    .pause(2500) // Pause briefly
                    .options({ loop: true }) // Set the loop option to true
                    .go(); // Start the typing animation

                  return instance;
                }}/>      
              </h1>
              <hr />
              <p>
                Every 3D animator needs a copilot to help optimize their time and effort. Text2Motion is yours.
              </p>
              <br />
              <CustomButton label="Try it" link="/#Playground" />
            </ContentTile>
            <ContentTile width="100%" height="40em">
              <h1>Animation through the power of
                <em><AnimatedText
                  in={{ effect: InEffect.FadeIn, delay: 100, shuffle: true }}
                  out={{ effect: OutEffect.FadeOut, delay: 100, shuffle: true }}
                  loop={true}
                  text=" generative " />
                </em>AI
              </h1>
              <hr />
              <p>We get it, animation is hard, time-consuming and requires specialized skills and/or expensive equipment.
                However, in the age of generative Al, we believe this is <i>no longer necessary.</i>
              </p>
              <br />
              <CustomButton label="Learn more" link="/faq/#what-is-t2m" />
            </ContentTile>
            <ContentTile width='100%' height='40em'>
              <h1>
                Your
                <em>
                  <Textillate option={{ in: "fadeIn", out: "fadeOut", loop: true }}>
                    <ul className="texts">
                      <li data-in-effect="fadeIn" data-out-effect="fadeOut"> ideas</li>
                      <li data-in-effect="fadeIn" data-out-effect="fadeOut"> tools</li>
                      <li data-in-effect="fadeIn" data-out-effect="fadeOut"> characters</li>
                      <li data-in-effect="fadeIn" data-out-effect="fadeOut"> stories</li>
                      <li data-in-effect="fadeIn" data-out-effect="fadeOut"> creativity</li>
                      <li data-in-effect="fadeIn" data-out-effect="fadeOut"> time</li>
                    </ul>
                  </Textillate>
                </em>, our magic.
              </h1>
              <hr />
              <p>
                Use the available REST APIs, or one of our integrations to bring characters to life in <i>your</i> favorite tools and workflows.
              </p>
              <br />
              <CustomButton label="Develop" link="/#Integrations" />
            </ContentTile>
          </CarouselTile>

        </TileManager>
        <TileManager orientation="vertical" height="auto">
          <VideoTile width="100%" height="35em" videoSrc="https://www.youtube.com/embed/bsru2ADlSro?si=BWB7Fb4Zg0iYUUqe" embed={true} description="" />
          <BlankTile width="100%" height="auto">
            <p className=".ContentTile p">Create animation in a fraction of the time and effort.</p>
            <div className="downArrow"><i className="fa-solid fa-angle-down"></i></div>
          </BlankTile>
        </TileManager>
        <PlaygroundTile
          id="Playground"
          width="100%"
          height="500px"
          expandedWidth="100vw"
          expandedHeight="95vh"
        />
        <TileManager orientation="horizontal">
          <TileSet width="100%" height="auto" showNavButtons="true" navButtonPos="bottom center" title="Integrations" autoplaySpeed={8000}>
            <ContentTile width="100%" height="20em" showBadge={true} badgeText="new" href="https://github.com/text2motion/blender-integration" inNewTab={true}>
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/Blender-dark.svg" lightImage="./assets/images/Blender-light.svg" />
              </section>
            </ContentTile>
            <ContentTile width="100%" height="20em" showBadge={true} badgeText="new" href="https://github.com/text2motion/unity-integration" inNewTab={true}>
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/Unity-dark.svg" lightImage="./assets/images/Unity-light.svg" />
              </section>
            </ContentTile>
            <ContentTile width="100%" height="20em">
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/UnrealEngine-dark.svg" lightImage="./assets/images/UnrealEngine-light.svg" />
              </section>
              <small><em>Coming soon!</em></small>
            </ContentTile>
            <ContentTile width="100%" height="20em">
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/Godot-dark.svg" lightImage="./assets/images/Godot-light.svg" />
              </section>
              <small><em>Coming soon!</em></small>
            </ContentTile>
          </TileSet>
        </TileManager>
        <TileManager orientation="horizontal">
          <TileSet width="100%" height="auto" title="For Developers">
            <ContentTile width="100%" height="20em" href="https://developer.text2motion.ai/">
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/Swagger-dark.svg" lightImage="./assets/images/Swagger-light.svg" />
              </section>
              <p>REST API</p>
            </ContentTile>
            <ContentTile width="100%" height="20em" href="https://developer.text2motion.ai/apis">
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/Docs-dark.svg" lightImage="./assets/images/Docs-light.svg" />
              </section>
              <p>Documentation</p>
            </ContentTile>
            <ContentTile width="100%" height="20em" href="https://github.com/text2motion">
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/Github-dark.svg" lightImage="./assets/images/Github-light.svg" />
              </section>
              <p>Github</p>
            </ContentTile>
            <ContentTile width="100%" height="20em" link="/faq">
              <section>
                <ThemeImage width="200px" darkImage="./assets/images/FAQ-dark.svg" lightImage="./assets/images/FAQ-light.svg" />
              </section>
              <p>FAQ</p>
            </ContentTile>
          </TileSet>
        </TileManager>
        <TileManager orientation="horizontal" id="Contact" title="Contact">
          <div className="contact-section">
            <ContentTile width="100%" height="auto">
              <h3>
                User Inquiries
              </h3>
              <p>
                If you have any questions about using Text2Motion or our features,
                feel free to reach out to our support team. They're friendly experts
                happy to assist you in any way they can.
              </p>
              <CustomButton label="Contact us" link="/contact" />
            </ContentTile>
            <ContentTile width="100%" height="auto">
              <h3>
                Interested in a career with us?
              </h3>
              <p>
                We're passionate about the potential of Text2Motion and its impact on the creative industry. If you feel like Text2Motion sounds like somewhere you could thrive, we'd love to hear from you.
              </p>
              <CustomButton label="Work with us" href="mailto:careers@text2motion.ai" />
            </ContentTile>
            <ContentTile width="100%" height="auto">
              <h3>
                Invest in us
              </h3>
              <p>
                We believe Text2Motion has the potential to disrupt the 3D animation landscape. If you're an investor looking for groundbreaking opportunities, we'd love to hear from you.
              </p>
              <CustomButton label="Get in touch" link="/investors" />
            </ContentTile>
          </div>
        </TileManager>
      </TileManager>
    )
  }
}