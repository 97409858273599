import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import MyThree from "./components/Mythree";

import "./index.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";

import { Home } from "./components/Home";
import { Faq } from "./components/Faq";
import { ContactForm } from "./components/ContactForm";

import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import NavLinks from "./components/NavLinks";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <HashRouter>
      <App>
        <Header>
          <NavLinks className="NavLinks center" />
        </Header>
        <MyThree />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<ContactForm 
              name="Support Contact Form submission"
              accessKey="0331610b-203b-40a6-b371-0eb3cf3f1b5e"
              roles={ {
                "": "Select a role*",
                "gamedev": "Game Developer",
                "animator": "Animator",
                "vfx": "VFX Artist",
                "education": "Educator",
                "other": "Other"
            } } />} />
            <Route path="/investors" element={<ContactForm 
              name="Investor Contact Form submission"
              accessKey="4cbdcd87-4fa9-49cc-a060-3140a0251348"
              subTitle="Interested in investing in our platform? Please fill in the form below and we will get back to you as soon as possible!"
              roles={ {
                "": "Type of Investor*",
                "angel": "Angel Investor",
                "vc": "Venture Capitalist",
                "accelerator": "Accelerator",
                "incubator": "Incubator",
                "other": "Other"
            } } />} />
          </Routes>
        <Footer />
      </App>
    </HashRouter>
  );
}