// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge__container {
  background-color: var(--badge-bg-color);
  color: var(--badge-fg-color);
  padding: 0.2rem;
  box-shadow: -5px 5px 6px 1px rgba(0, 0, 0, 0.2);
}
.badge__container.positionWrapper {
  position: absolute;
}
.badge__container.topLeft {
  top: 0;
  left: 0;
  border-radius: 10px 0 0 0;
}
.badge__container.topRight {
  top: 0;
  right: 0;
  border-radius: 0 10px 0 0;
}
.badge__container span {
  font-size: 1rem;
  font-weight: 400;
  color: var(--badge-fg-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Badge.scss"],"names":[],"mappings":"AAAA;EAEI,uCAAA;EACA,4BAAA;EACA,eAAA;EACA,+CAAA;AAAJ;AAGI;EACI,kBAAA;AADR;AAII;EACI,MAAA;EACA,OAAA;EACA,yBAAA;AAFR;AAKI;EACI,MAAA;EACA,QAAA;EACA,yBAAA;AAHR;AAMI;EACI,eAAA;EACA,gBAAA;EACA,4BAAA;AAJR","sourcesContent":[".badge__container {\n\n    background-color: var(--badge-bg-color);\n    color: var(--badge-fg-color);\n    padding: 0.2rem;\n    box-shadow: -5px 5px 6px 1px rgba(0, 0, 0, 0.2);\n    \n  \n    &.positionWrapper {\n        position: absolute;\n    }\n\n    &.topLeft {\n        top: 0;\n        left: 0;\n        border-radius: 10px 0 0 0;\n    }\n    \n    &.topRight {\n        top: 0;\n        right: 0;\n        border-radius: 0 10px 0 0;\n    }\n\n    span {\n        font-size: 1rem;\n        font-weight: 400;\n        color: var(--badge-fg-color);\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
