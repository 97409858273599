import React from 'react';
import { Tile, TileProps } from './Tile';

import './BlankTile.scss';

class BlankTile extends Tile<TileProps> {
    render() {
        const { width, height, children } = this.props; // Default width and height are set to 100
        const style: React.CSSProperties = {
            width: `${width}`,
            height: `${height}`
        };

        return (
            <div className={this.getClassName()} style={style}>
                {children}
            </div>
        );
    }
}

export default BlankTile;