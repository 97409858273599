import { AnimationClip, Bone, Euler, Quaternion, Vector3, QuaternionKeyframeTrack, VectorKeyframeTrack, Skeleton } from "three";

export class JsonAnimationLoader {
	parse(text) {
		const that = this;

		function toTHREEAnimation(json) {

			const tracks = [];

			// create a position and quaternion animation track for each node
			for (const [bone_name, bone_data] of Object.entries(json.bones)) {
				
				if ("rotation" in bone_data) {
					const times = Object.keys(bone_data.rotation).map(parseFloat);
					const rotations = []
					Object.values(bone_data.rotation).map((r) => {
						rotations.push(r[0]);
						rotations.push(r[1]);
						rotations.push(r[2]);
						rotations.push(r[3]);
					});
					tracks.push(new QuaternionKeyframeTrack(`.bones[${bone_name}].quaternion`, times, rotations));
				}

				if ("position" in bone_data) {
					const times = Object.keys(bone_data.position).map(parseFloat);
					const positions = [];
					Object.values(bone_data.position).map((p) => {
						positions.push(p[0] /*+ bones[bone_name].position.x*/);
						positions.push(p[1] /*+ bones[bone_name].position.y*/);
						positions.push(p[2] /*+ bones[bone_name].position.z*/);
					});
					tracks.push(new VectorKeyframeTrack(`.bones[${bone_name}].position`, times, positions));
				}
			}

			return new AnimationClip('animation', - 1, tracks);
		}

		const json = JSON.parse(text);
		const threeClip = toTHREEAnimation(json);
		return threeClip
	}
}