import React from 'react';
import { ThemeAwareComponent } from './Themeable';

// Define the props interface
interface ThemeImageProps {
    width?: string;
    height?: string;
    lightImage?: string;
    darkImage?: string;
    alt?: string;
}

interface  ThemeImageState {
    imagePath?: string;
};

class ThemeImage extends ThemeAwareComponent<ThemeImageProps> {
    state: ThemeImageState = { imagePath: this.getTheme() === 'light' ? this.props.lightImage : this.props.darkImage,};
    protected onThemeChanged() {
        console.log("Theme Changed");
        this.setState({ imagePath: this.getTheme() === 'light' ? this.props.lightImage : this.props.darkImage,});
    }

  render() {
    const { lightImage, darkImage, alt, width, height } = this.props;
    const {imagePath} = this.state;
    const style: React.CSSProperties = {
      width: `${width}`,
      height: `${height}`
    };
    return (
      <>
        <img src={imagePath} alt={alt} style={style}/>
      </>
    );
  }
}

export default ThemeImage;