import React, { Component } from 'react';
import CookieContext from './CookieContext';
import Initials from './Initials';

interface ProfileImageProps {
    onChildClick: () => void;
}

export class ProfileImage extends Component<ProfileImageProps> {

    constructor(props: ProfileImageProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

     // Specify the context type
     static contextType = CookieContext;
     context!: React.ContextType<typeof CookieContext>;
    
     handleClick() {
        // Call the parent method passed as a prop
        this.props.onChildClick();
      }

    render() {
        if (!this.context) {
            return <div>Error: No context found</div>;
        }

        const { cookieData } = this.context;
        return (
            <>
                {!cookieData.picture ? (
                            <a href='#' className='profileIcon' onClick={this.handleClick}>
                                <Initials name={cookieData.user}/>
                            </a>
                        ) : (
                            <a href='#' className='profileIconWithImage' onClick={this.handleClick}>
                                <img src={cookieData.picture}/>
                            </a>
                        )}
            </>
        );
    }
}