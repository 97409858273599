import React from 'react';
import { Tile, TileProps } from './Tile';

import './VideoTile.scss';

interface VideoTileProps extends TileProps {
    title?: string;
    videoSrc: string;
    description?: string;
    embed?: boolean;
    aspectRatio?: number;
};

class VideoTile extends Tile<VideoTileProps> {
    constructor(props: VideoTileProps) {
        super(props);
    }

    render() {
        let { width, height, videoSrc, title, description, embed } = this.props as VideoTileProps;
        const style: React.CSSProperties = {
            width: `${width}`,
            height: `${height}`
        };

        const embedStyle: React.CSSProperties = {
            borderRadius: "inherit",
            width: "inherit",
            height: "inherit"
        };

        return (
            <div className={this.getClassName()} style={style}>
                {
                    embed ?
                    <iframe style={embedStyle} aspect-ratio={16/9} src={videoSrc} title={title ?? description ?? ""} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe> :
                    <video src={videoSrc} autoPlay muted loop playsInline/>
                }                    
                {
                    description ?
                    <div>
                        <p>{description}</p>
                    </div> :
                    ""
                }
                
            </div>
        );
    }
}

export default VideoTile;