import React, { ReactNode } from 'react';
import { Textillate } from 'textillate-react';

export enum InEffect {
    Flash = "flash",
    Bounce = "bounce",
    Shake = "shake",
    Tada = "tada",
    Swing = "swing",
    Wobble = "wobble",
    Pulse = "pulse",
    Flip = "flip",
    FlipInX = "flipInX",
    FlipInY = "flipInY",
    FadeIn = "fadeIn",
    FadeInUp = "fadeInUp",
    FadeInDown = "fadeInDown",
    FadeInLeft = "fadeInLeft",
    FadeInRight = "fadeInRight",
    FadeInUpBig = "fadeInUpBig",
    FadeInDownBig = "fadeInDownBig",
    FadeInLeftBig = "fadeInLeftBig",
    FadeInRightBig = "fadeInRightBig",
    BounceIn = "bounceIn",
    BounceInDown = "bounceInDown",
    BounceInUp = "bounceInUp",
    BounceInLeft = "bounceInLeft",
    BounceInRight = "bounceInRight",
    RotateIn = "rotateIn",
    RotateInDownLeft = "rotateInDownLeft",
    RotateInDownRight = "rotateInDownRight",
    RotateInUpLeft = "rotateInUpLeft",
    RotateInUpRight = "rotateInUpRight",
    RollIn = "rollIn"
};

export enum OutEffect {
    Flash = "flash",
    Bounce = "bounce",
    Shake = "shake",
    Tada = "tada",
    Swing = "swing",
    Wobble = "wobble",
    Pulse = "pulse",
    Flip = "flip",
    FlipOutX = "flipOutX",
    FlipOutY = "flipOutY",
    FadeOut = "fadeOut",
    FadeOutUp = "fadeOutUp",
    FadeOutDown = "fadeOutDown",
    FadeOutLeft = "fadeOutLeft",
    FadeOutRight = "fadeOutRight",
    FadeOutUpBig = "fadeOutUpBig",
    FadeOutDownBig = "fadeOutDownBig",
    FadeOutLeftBig = "fadeOutLeftBig",
    FadeOutRightBig = "fadeOutRightBig",
    BounceOut = "bounceOut",
    BounceOutDown = "bounceOutDown",
    BounceOutUp = "bounceOutUp",
    BounceOutLeft = "bounceOutLeft",
    BounceOutRight = "bounceOutRight",
    RotateOut = "rotateOut",
    RotateOutDownLeft = "rotateOutDownLeft",
    RotateOutDownRight = "rotateOutDownRight",
    RotateOutUpLeft = "rotateOutUpLeft",
    RotateOutUpRight = "rotateOutUpRight",
    Hinge = "hinge",
    RollOut = "rollOut"
}

interface TransitionProps {
    shuffle?: boolean;
    sync?: boolean;
    reverse?: boolean;
    delayScale?: number;
    delay?: number;
}

interface InTransitionProps extends TransitionProps {
    effect: InEffect;
}

interface OutTransitionProps extends TransitionProps {
    effect: OutEffect;
}

interface AnimatedTextProps {
    in?: InTransitionProps;
    out?: OutTransitionProps;
    
    loop?: boolean;
    minDisplayTime?: number;
    initialDelay?: number;
    autoStart?: boolean;
    text: string;
}

class AnimatedText extends React.Component<AnimatedTextProps> {
    render() {
        return (
            <Textillate
                option={{
                    in: this.props.in,
                    out: this.props.out,
                    loop: this.props.loop,
                    minDisplayTime: this.props.minDisplayTime,
                    initialDelay: this.props.initialDelay,
                    autoStart: this.props.autoStart
                }}>
                {this.props.text}
            </Textillate>
        );
    }
}

export default AnimatedText;