import React, { ReactNode } from 'react';
import { Tile, TileProps } from './Tile';

import './ContentTile.scss';
import Badge from './Badge';
import { Link } from 'react-router-dom';

interface ContentTileProps extends TileProps {
    showBadge? : boolean;
    badgeText? : string;
    badgePosition? : 'top-right' | 'top-left';
    href?: string;
    link? : string;
    inNewTab?: boolean;
}

class ContentTile extends Tile<ContentTileProps> {
    protected override getClassName(): string {
        return `Tile ContentTile`;
    }

    // Helper method to render the badge if required
  renderBadge() {
    const { showBadge, badgePosition, badgeText } = this.props;
    if (!showBadge) return null;

    return (
      <Badge position={badgePosition ?? 'top-right'}>
        <span>{badgeText}</span>
      </Badge>
    );
  }

  // Helper method to define the wrapper based on link or href props
  renderWrapper(content: React.ReactNode) {
    const { href, link, inNewTab } = this.props;
    const style: React.CSSProperties = {
        textDecoration: href || link ? 'none' : 'default',// Set textDecoration to none if href or link is set
    };
    // Case 1: If `link` prop is provided, use the `Link` component from react-router-dom
    if (link) {
      return (
        <Link to={link} style={style}>
          {content}
        </Link>
      );
    }

    // Case 2: If `href` prop is provided, render an anchor tag
    if (href) {
      return (
        <a
          href={href}
          target={inNewTab ? '_blank' : '_self'}
          rel="noopener noreferrer"
          style={style}
        >
          {content}
        </a>
      );
    }

    // Case 3: Default to rendering a div if neither `link` nor `href` is provided
    return (
      <>
        {content}
      </>
    );
  }

    render() {
        const { children, width, height, href, link} = this.props; // Default width and height are set to 100
        const style: React.CSSProperties = {
            width: `${width}`,
            height: `${height}`,
            textDecoration: href || link ? 'none' : 'default',
            cursor: href || link ? 'pointer' : 'default' // Set cursor to pointer if href or link is set
        };
        // The content to be rendered inside the wrapper (children + badge)
        const content = (
            <>
            {this.renderBadge()}
            {children}
            </>
        );

        return this.renderWrapper(
            <div className={this.getClassName()} style={style}>
                {content}
            </div>
        );
    }
}

export default ContentTile;