// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavLinks {
  display: flex;
  gap: 2.5rem;
  flex-grow: 1;
}
.NavLinks.center {
  justify-content: center;
}
.NavLinks a {
  text-decoration: none;
  color: var(--color-fg);
}
.NavLinks a:hover {
  color: var(--color-hover);
}`, "",{"version":3,"sources":["webpack://./src/components/NavLinks.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,uBAAA;AACR;AAEI;EACI,qBAAA;EACA,sBAAA;AAAR;AAGI;EACI,yBAAA;AADR","sourcesContent":[".NavLinks {\n    display: flex;\n    gap: 2.5rem;\n    flex-grow: 1;\n\n    &.center {\n        justify-content: center;\n    }\n\n    a {\n        text-decoration: none;\n        color: var(--color-fg);\n    }\n\n    a:hover {\n        color: var(--color-hover);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
