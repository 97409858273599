// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: var(--color-bg);
  text-align: right;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
html, body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,iBAAA;AACJ;;AAEA,iDAAA;AACA;EACI,aAAA;AACJ;;AAEA,6CAAA;AACA;EACI,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AACJ","sourcesContent":[".App {\n    background-color: var(--color-bg);\n    text-align: right;\n}\n\n/* Hide scrollbar for Chrome, Safari, and Opera */\nhtml::-webkit-scrollbar, body::-webkit-scrollbar {\n    display: none;\n}\n\n/* Hide scrollbar for IE, Edge, and Firefox */\nhtml, body {\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
