import React, { createRef, RefObject } from 'react';

import { ThemeAwareComponent } from './Themeable';
import CustomButton from './CustomButton';
import CookieContext from './CookieContext';

import './LoginButton.scss';
import Initials from './Initials';
import { ProfileImage } from './ProfileImage';

export interface LoginButtonProps {
    showProfile ?: boolean;
    text ?: string;
}

interface  LoginButtonState {
    isLoggedIn: boolean;
    isDropdownVisible: boolean;
  };

class LoginButton extends ThemeAwareComponent<LoginButtonProps> {
    state: LoginButtonState = { isLoggedIn: false, isDropdownVisible: true };

    private menuRef: RefObject<HTMLDivElement>;
    // Specify the context type
    static contextType = CookieContext;
    context!: React.ContextType<typeof CookieContext>;

    constructor(props: LoginButtonProps) {
        super(props);
        this.menuRef = createRef();
    }

    handleLogin = () => {
        if (this.context && !this.context.isLoggedIn()) {
            this.context.login(); // Call the login function from context
        }
    };

    handleLogout = () => {
        if (this.context) {
            this.context.logout(); // Call the logout function from context
        }
        this.setState({ isLoggedIn: false, isDropdownVisible: false });
    };

    componentDidMount() {
        window.addEventListener('click', this.handleWindowClick.bind(this));
        console.log(' componentDidMount Context in ChildComponent:', this.context);
        
        this.setState ({
            isLoggedIn: !!this.context?.isLoggedIn(),
            isDropdownVisible: false
        });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleWindowClick.bind(this));
    }

    handleWindowClick(event: MouseEvent) {
    // If menu is open and click is outside the menu, close it
        if (this.state.isDropdownVisible && this.menuRef.current && !this.menuRef.current.contains(event.target as Node)) {
            this.setState({ isDropdownVisible: false });
        }
    }

    toggleDropdown = () => {
        this.setState((prevState:LoginButtonState) => ({
        isDropdownVisible: !prevState.isDropdownVisible,
        }));
    };

    render() {

        if (!this.context) {
            return <div>Error: No context found</div>;
        }

        const { isLoggedIn, isDropdownVisible } = this.state;
        const { cookieData } = this.context;
        const { showProfile, text } = this.props;
        return (
            <div ref={this.menuRef} className='loginButton'>
                <div className='loginContainer'>
                    {!isLoggedIn ? (
                    <CustomButton onClick={this.handleLogin} label={text ?? "Login"}/>
                    ) : (
                        
                        showProfile && (<div>
                        <ProfileImage onChildClick={this.toggleDropdown}/>
                        
                        {isDropdownVisible && (
                        <div className="dropdown">
                            <div className='profile__details'>
                                <div>
                                    {cookieData.givenName}
                                </div>
                                <div>
                                    <ProfileImage onChildClick={this.toggleDropdown}/>
                                </div>
                                <div>
                                    {cookieData.email}
                                </div>
                            </div>
                            <div className='logout__container'>
                                <a onClick={this.handleLogout}>
                                    <span>Log out</span>
                                </a>
                            </div>
                        </div>
                        )}
                    </div>)
                    )}
                </div>
            </div>
        );
    }
}

export default LoginButton;