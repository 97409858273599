import { ReactNode } from 'react';
import { ThemeAwareComponent } from './Themeable';

import './Tile.scss';

export interface TileProps {
    id?: string; // Optional id property
    children?: ReactNode; // Allow tiles to have children
    width?: string; // Optional width property
    height?: string; // Optional height property
    debug?: boolean; // Debug property for testing
    background?: "opaque" | "blurred" | "transparent"
}

export class Tile<T extends TileProps> extends ThemeAwareComponent<T> {
    // private ClassName = "Tile";

    constructor(props: T) {
        super({ ...props, tiles: props.background ?? "opaque"});
    }

    protected getBackgroundColor(bck: undefined | "opaque" | "blurred" | "transparent", backgroundRgb: string): string {
        switch (bck) {
            case 'opaque':
                return backgroundRgb;
            
            case undefined:
            case 'blurred':
                return backgroundRgb.replace('rgb', 'rgba').replace(')', ', 0.5)');
                
            case 'transparent':
                return 'transparent';
            default:
                return backgroundRgb.replace('rgb', 'rgba').replace(')', ', 0.0)');
        };
    }

    protected getClassName(): string {
        const { debug } = this.props;
        return `${this.constructor.name}${debug ?? false ? ' debug' : ''}`;
    }
}