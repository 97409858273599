import React, { ReactNode } from 'react';

import { ThemeAwareComponent } from './Themeable';

import './Header.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import LoginButton from './LoginButton';
import ThemeButton from './ThemeButton';
import HamburgerMenu from './HamburgerMenu';
import ThemeImage from './ThemeImage';

interface HeaderProps {
    children ?: ReactNode;
};

export class Header extends ThemeAwareComponent<HeaderProps> {
    private lastScrollY: number;
    private isVisible: boolean = true;
      
    constructor(props: HeaderProps) {
        super(props);
        this.lastScrollY = window.scrollY;
    }

    private handleScroll() {
        if(window.scrollY < 0) {
            return;
        }
        if (window.scrollY > this.lastScrollY) {
            this.isVisible = false;
        } else {
            this.isVisible = true;
        }
        this.lastScrollY = window.scrollY;

        this.forceUpdate();
    }

    componentDidMount() {
        window.addEventListener('scroll',  this.handleScroll.bind(this));
    }

    render() {
        const that = this;
        const {children} = this.props;
        return (
            <header className={`Header ${this.isVisible ? 'visible' : 'hidden'}`}>
                <div className="Header-content">
                    <HamburgerMenu>
                    {children}
                    </HamburgerMenu>
                    <a href="/" className="Header-logo">
                        <ThemeImage width="90px" darkImage="./assets/images/logo_dark.svg" lightImage="./assets/images/logo_light.svg"/>
                    </a>
                    <nav className="Header-nav">
                        {children}
                    </nav>
                    <div className='actionsContainer'>
                        <ThemeButton/>
                        <LoginButton showProfile={true}/>
                    </div>
                    
                </div>
            </header>
        );
    }
}