import * as THREE from 'three';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BVH, BVHLoader } from 'three/examples/jsm/loaders/BVHLoader';
import { JsonBVHLoader } from './utils/JsonBVHLoader';
import { clone, retargetClip } from './utils/SkeletonUtilsExt';
import { SkeletonHelperExt } from "./utils/SkeletonHelperExt";
import { displayToastNotification } from './utils/Toasts';
import { JsonAnimationLoader } from './utils/JsonAnimationLoader';
import { Bone, Matrix4, Skeleton } from 'three';
import { BVHExporter } from './utils/BVHExporter';

import { SequencerPane } from './SequencerPane';

import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

const NUM_SIGNIFICANT_DIGITS = 8;
const MIN_PROMPT_LENGTH = 3;

interface TargetSkeletonData {
    world_matrix: number[];
    root: TargetBoneData;
};

interface TargetBoneData {
    name: string;
    matrix: number[];
    children: TargetBoneData[];
};

class Model extends THREE.Object3D {

    private _gltf: GLTF | null = null;
    private _skinnedMesh: THREE.SkinnedMesh | null = null;
    private _skeleton: SkeletonHelperExt | null = null;
    private _mixer: THREE.AnimationMixer | null = null;
    private _aabb = new THREE.Box3Helper(new THREE.Box3(), new THREE.Color(0xffff00));
    private _modelName: string | null = null;
    private _prompt: string | null = null;
    private _target_skeleton_data: string = "";
    private _sequencer: SequencerPane;
    // private _currentAnimationIndex: number = 0;
    private _time: number = 0;

    private _apiKey: string | null = null;
    private _apiEndpoint: string | null = null;

    private _clock = new THREE.Clock();

    onBusy: () => void = () => { };
    onDone: () => void = () => { };

    private onSequencerChanged() {
        if (this._sequencer.playing)
            this._sequencer.pause();
    }

    private onSequencerClear() {
        if (this._mixer) {
            this._mixer.stopAllAction();
            this._time = 0;
        }
    }

    private saveArrayBuffer(buffer: ArrayBuffer, filename: string) {
        this.save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
    }

    private save(blob: Blob, filename: string) {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.click();
      
        URL.revokeObjectURL(url);
    }

    private onExport(prompt: string) {
        if (this._gltf &&
            this._skinnedMesh && 
            this._mixer && 
            (this._sequencer.animations.length > 0) && 
            this._sequencer.animations[0].generated) {
            
            const exporter = new GLTFExporter();
            const animation = this._mixer!.clipAction(this._sequencer.animations[0].action!.getClip()).getClip().clone();
            // Remove the .bones[ prefix and ] suffix from the track names
            animation.tracks.forEach(track => {
                track.name = track.name.replace(".bones[", "").replace("]", "");
            });
            // Add the animation to the gltf scene
            this._gltf.scene.animations = [ animation ];

            // Add the bone to the rootNode
            this._gltf.scene.children[0].add(this._skinnedMesh.skeleton.bones[0]);

            exporter.parse(this._gltf.scene, (gltf) => {
                this.saveArrayBuffer(gltf as ArrayBuffer, `${prompt}.glb`);
            },
            (error) => {
                console.error('An error occurred while exporting the animation!', error);
                displayToastNotification("An error occurred while exporting the animation!", "fa-xmark", "#c0392b", "slide-in-slide-out");
            },
            {
                binary: true,
                animations: [ animation ]
            });            
        }
    }

    constructor(sequencer: SequencerPane) {
        super();
        this._sequencer = sequencer;
        this._sequencer.onChanged = this.onSequencerChanged.bind(this);
        this._sequencer.onClear = this.onSequencerClear.bind(this);
        this._sequencer.onExport = this.onExport.bind(this);
    }

    get apiKey(): string | null {
        return this._apiKey;
    }
    set apiKey(value: string | null) {
        this._apiKey = value;
    }

    get apiEndpoint(): string | null {
        return this._apiEndpoint;
    }
    set apiEndpoint(value: string | null) {
        this._apiEndpoint = value
    }

    createStaticAnimation(name: string) {
        const duration = 2; // Duration of the animation in seconds

        // Calculate keyframes for sinusoidal motion
        const times = []; // Keyframe times
        const values = []; // Keyframe values for Y position
        const numKeyframes = 30; // Number of keyframes

        for (let i = 0; i <= numKeyframes; i++) {
            const t = (i / numKeyframes) * duration;
            times.push(t);
            const y = 1.0 - 0.3;
            values.push(0);
            values.push(y);
            values.push(0);
        }

        // Create a keyframe track for the Y position
        const positionTrack = new THREE.VectorKeyframeTrack(
            `${name}.position`, // Assuming the object has a name, otherwise 'object' is used
            times,
            values
        );

        // Create an animation clip
        return new THREE.AnimationClip('Bobbing', duration, [positionTrack]);
    }

    createBobbingAnimation(name: string) {
        const duration = 2; // Duration of the animation in seconds
        const baseline = 1.0;
        const amplitude = 0.025; // How high and low the object will bob
        const frequency = 2 * Math.PI / duration; // Complete a cycle every `duration` seconds

        // Calculate keyframes for sinusoidal motion
        const times = []; // Keyframe times
        const values = []; // Keyframe values for Y position
        const numKeyframes = 30; // Number of keyframes

        for (let i = 0; i <= numKeyframes; i++) {
            const t = (i / numKeyframes) * duration;
            times.push(t);
            const y = baseline + amplitude * Math.sin(frequency * t);
            values.push(0);
            values.push(y);
            values.push(0);
        }

        // Create a keyframe track for the Y position
        const positionTrack = new THREE.VectorKeyframeTrack(
            `${name}.position`, // Assuming the object has a name, otherwise 'object' is used
            times,
            values
        );

        // Create an animation clip
        return new THREE.AnimationClip('Bobbing', duration, [positionTrack]);
    }

    getBasenameWithoutExtension(urlString: string): string {
        const pathname = urlString.split('?')[0]; // Remove query string
        const basename = pathname.split('/').pop() || ''; // Extract basename
        return basename.split('.').slice(0, -1).join('.') || basename; // Remove extension if present
    }

    getHipBone(skinnedMesh: THREE.SkinnedMesh): THREE.Bone | null {
        for (const bone of skinnedMesh.skeleton.bones) {
            if (bone.name.endsWith("Hips")) {
                return bone;
            }
        }
        return null;
    }

    async load(asset_url: string): Promise<boolean> {

        // const reAnimate = this._clip && this._skinnedMesh && this._prompt && this._prompt.length > MIN_PROMPT_LENGTH ? true : false;

        this.unload();
        this._sequencer.clear();

        const gltfLoader = new GLTFLoader();
        try 
        {
            const { gltf, skinnedMesh } = await new Promise<{ gltf: GLTF, skinnedMesh: THREE.SkinnedMesh }>((resolve, reject) => {
                gltfLoader.load(
                    // resource URL
                    asset_url,
                    // onLoad callback
                    (gltf) => {
                        gltf.scene.traverse((child) => {
                            if (child.constructor.name == 'SkinnedMesh') {
                                child.castShadow = true;
                                child.receiveShadow = true;
                                // ((child as THREE.SkinnedMesh).material as THREE.MeshStandardMaterial).transparent = true;
                                // ((child as THREE.SkinnedMesh).material as THREE.MeshStandardMaterial).side = THREE.DoubleSide;
                                // ((child as THREE.SkinnedMesh).material as THREE.MeshStandardMaterial).alphaTest = 0.04;
                                // ((child as THREE.SkinnedMesh).material as THREE.MeshStandardMaterial).opacity = 1;                            
                                const skinnedMesh = child as THREE.SkinnedMesh;

                                const hipBone = this.getHipBone(skinnedMesh);
                                if (!hipBone) {
                                    reject(new Error('Target skeleton does not have a bone that ends with "Hips", is it a mixamo rig?'));
                                }

                                const bonePrefix = hipBone!.name.replace("Hips", "");

                                for (const bone of skinnedMesh.skeleton.bones) {
                                    bone.name = bone.name.replace(bonePrefix, "mixamorig");
                                }

                                // Currently only supporting mixamo rigs. This should never happen because we
                                // only use mixamo rigs in the first place, but keep this for when we allow the user to
                                // upload their own models
                                const target_is_mixamo = skinnedMesh.skeleton.getBoneByName('mixamorigHips') != undefined;
                                if (!target_is_mixamo)
                                    reject(new Error('Target skeleton is not a mixamo rig!'));

                                resolve({ gltf, skinnedMesh });
                            }
                        });
                    },
                    // onProgress callback
                    (xhr) => {
                        console.log(`Loading ${asset_url} - ${(xhr.loaded / xhr.total * 100)}%`);
                    },
                    // onError callback
                    (error) => {
                        console.error(`An error occurred while loading ${asset_url}.`, error);
                        reject(error);
                    }
                );
            });
            this._gltf = gltf;
            this._skinnedMesh = skinnedMesh;
        } catch (error) {
            console.error('Target skeleton is not recognizable as a Mixamo rig!', error);
            displayToastNotification("Target skeleton is not recognizable as a Mixamo rig!", "fa-xmark", "#c0392b", "slide-in-slide-out");
            return false;
        }

        // Add the loaded model as a child of this object.
        this.add(this._gltf!.scene);

        this._skeleton = new SkeletonHelperExt(this._gltf!.scene);
        this._skeleton.visible = false;
        (this._skeleton.material as THREE.LineBasicMaterial).linewidth = 1.5;
        this.add(this._skeleton);
        this.add(this._skinnedMesh!.skeleton.bones[0] as THREE.Object3D);

        this._mixer = new THREE.AnimationMixer(this._skinnedMesh);
        this._sequencer.onGenerateAnimation = async (animation) => {
            this._sequencer.reset();
            if (animation.action) {
                const mixer = animation.action.getMixer();
                mixer.stopAllAction();
            }
            animation.action = await this.generateAnimation(animation.prompt, animation.length, animation.version);
            animation.range.min = 0;
            animation.range.max = animation.action.getClip().duration;
            animation.generated = true;
            const index = 0;
            if (this._sequencer.playing && this._sequencer.currentTrack == index) {
                animation.action.play();
            }
        };
        this._sequencer.onPlayAnimation = (startIndex) => {
            if (this._sequencer.playing) {
                this._sequencer.currentTrack = startIndex;
                const action = this._sequencer.animations[startIndex].action;
                this._time = this._sequencer.animations[startIndex].lastTime ?? 0;
                if (action) {
                    if (action.paused)
                        action.paused = false;
                    else
                        action.play();
                }
            }
            else {
                this._sequencer.animations[startIndex].lastTime = this._time;
                const action = this._sequencer.animations[startIndex].action;
                if (action)
                    action.paused = true;
            }
        };


        // Save the target skeleton bindpose data as soon as it's loaded
        // Note that we're saving it as a string so that it can be sent to the server,
        // and so any local changes to references are guaranteed to have no effect on the data
        this._target_skeleton_data = JSON.stringify(this.dump_target_skeleton(this._skinnedMesh));

        if (this.isDebugMode()) {
            const sourceData = this._target_skeleton_data;
            this._modelName = this.getBasenameWithoutExtension(asset_url);
            this.downloadText(sourceData, `target_pose_${this._modelName}.json`);
        }

        this._aabb = new THREE.Box3Helper(new THREE.Box3(), new THREE.Color(0xffff00));
        this._aabb.visible = false;
        this.add(this._aabb);

        return true;

        // When a new model is loaded, if an existing model output is populated, reanimate the model
        // if (reAnimate) {
        //     // TODO: This re-animates, but should actually retarget. Fix.
        //     await this.animate(this._prompt ?? "");
        // }
        // else
        //     this._animation = this._mixer.clipAction(this.createBobbingAnimation('.bones[mixamorigHips]'));

        // this._animation?.play();
    }

    get skeleton() {
        return this._skeleton;
    }

    get aabb() {
        return this._aabb;
    }

    unload() {
        if (this._mixer) {
            this._mixer.stopAllAction();
            this._mixer = null;
        }
        if (this._gltf) {
            this.remove(this._gltf.scene);
            this._gltf = null;
        }
        if (this._skeleton) {
            this.remove(this._skeleton);
            this._skeleton = null;
        }
        if (this._aabb) {
            this.remove(this._aabb);
        }
        this._skinnedMesh = null;
    }

    // TODO: Fix this, we need to iterate over all meshes in the gltf.scene
    get wireframe() {
        return (this._skinnedMesh?.material as THREE.MeshBasicMaterial).wireframe || false;
    }

    set wireframe(value: boolean) {
        if (this._skinnedMesh) {
            (this._skinnedMesh.material as THREE.MeshBasicMaterial).wireframe = value;
        }
    }

    dump_position_track(track: THREE.VectorKeyframeTrack): { [key: number]: number[] } {
        const times = track.times;
        const values = track.values;

        const data: { [key: string]: number[] } = {};

        for (let i = 0; i < times.length; i++) {
            const key = times[i].toFixed(NUM_SIGNIFICANT_DIGITS);
            const value = [values[i * 3], values[i * 3 + 1], values[i * 3 + 2]];
            data[key] = value;
        }

        return data;
    }
    dump_quaternion_track(track: THREE.QuaternionKeyframeTrack): { [key: number]: number[] } {
        const times = track.times;
        const values = track.values;

        const data: { [key: string]: number[] } = {};

        for (let i = 0; i < times.length; i++) {
            const key = times[i].toFixed(NUM_SIGNIFICANT_DIGITS);
            const value = [values[i * 4], values[i * 4 + 1], values[i * 4 + 2], values[i * 4 + 3]];
            data[key] = value;
        }

        return data;
    }

    dump_animation_data(animation: THREE.AnimationClip) {
        const tracks = animation.tracks;
        const groupedTracks = tracks.reduce((acc, track) => {
            const matchResult = track.name.match(/(\.bones\[)?([^.\]]*)(\])?/);
            const boneName = matchResult ? matchResult[2] : '';
            if (!acc[boneName]) {
                acc[boneName] = {};
            }
            if (track.name.endsWith('.position')) {
                acc[boneName].position = this.dump_position_track(track as THREE.VectorKeyframeTrack);
            } else if (track.name.endsWith('.quaternion')) {
                acc[boneName].rotation = this.dump_quaternion_track(track as THREE.QuaternionKeyframeTrack);
            }
            return acc;
        }, {} as { [key: string]: { position?: { [key: number]: number[] }, rotation?: { [key: number]: number[] } } });

        return {
            duration: animation.duration,
            tracks: groupedTracks
        };
    }

    downloadJSON<T>(data: T, filename: string) {

        function replacer(key: string, value: any): any {
            if (typeof value === 'number') {
                return parseFloat(value.toFixed(NUM_SIGNIFICANT_DIGITS)); // Adjust the number of digits here
            }
            return value;
        }

        // Step 1: Convert data to JSON string and create a Blob
        const jsonString = JSON.stringify(data, replacer);
        const blob = new Blob([jsonString], { type: 'application/json' });

        // Step 2: Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Step 3: Create an anchor element and set properties for downloading
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;

        // Append anchor to body, trigger click, and remove it
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Cleanup by revoking the Blob URL
        URL.revokeObjectURL(url);
    }

    downloadText(text: string, filename: string) {
        const blob = new Blob([text], { type: 'application/text' });

        // Step 2: Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Step 3: Create an anchor element and set properties for downloading
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;

        // Append anchor to body, trigger click, and remove it
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Cleanup by revoking the Blob URL
        URL.revokeObjectURL(url);
    }

    isDebugMode() {
        const debugQueryParam = new URLSearchParams(window.location.search).get('debug');
        return debugQueryParam === 'true';
    }

    dump_target_skeleton(target: THREE.SkinnedMesh): TargetSkeletonData {
        // Create a map of bones names to bone indices
        function dump_bone_data(bone: THREE.Object3D): TargetBoneData {
            bone.updateMatrixWorld();

            return {
                name: bone.name,
                matrix: bone.matrix.elements,
                children: bone.children
                    .map(child => dump_bone_data(child))
            }
        }
        return {
            world_matrix: target.matrixWorld.elements,
            root: dump_bone_data(target.skeleton.bones[0])
        }
    }

    createSkeletonFromData(data: TargetSkeletonData): Skeleton {
        const result = new Skeleton();

        function createBoneFromData(data: TargetBoneData): Bone {
            const bone = new Bone();
            bone.name = data.name;
            bone.matrix = new Matrix4(data.matrix[0], data.matrix[4], data.matrix[8], data.matrix[12],
                data.matrix[1], data.matrix[5], data.matrix[9], data.matrix[14],
                data.matrix[2], data.matrix[6], data.matrix[10], data.matrix[14],
                data.matrix[3], data.matrix[7], data.matrix[11], data.matrix[15]);
            bone.matrix.decompose(bone.position, bone.quaternion, bone.scale);
            result.bones.push(bone);
            for (const childData of data.children) {
                let childBone = createBoneFromData(childData);
                bone.add(childBone);
            }
            return bone;
        }

        const skeletonData = {
            modelWorldMatrix: new Matrix4().fromArray(data.world_matrix),
            rootBone: createBoneFromData(data.root)
        };
        for (const bone of result.bones) {
            bone.updateMatrixWorld(true);
            result.boneInverses.push(bone.matrixWorld.clone().invert().multiply(skeletonData.modelWorldMatrix.clone()));
        }
        return result;
    }

    handle_error(status: number) {
        switch (status) {
            case 200:
            case 201:
                return true;
            case 401:
                displayToastNotification("Authorization error!", "fa-xmark", "#c0392b", "slide-in-slide-out");
                break;
            case 403:
                displayToastNotification("Forbidden!", "fa-xmark", "#c0392b", "slide-in-slide-out");
                break;
            case 404:
                displayToastNotification("Not found!", "fa-xmark", "#c0392b", "slide-in-slide-out");
                break;
            case 503:
            case 500:
                displayToastNotification("Internal server error", "fa-xmark", "#c0392b", "slide-in-slide-out");
                break;
            case 504:
                displayToastNotification("Timeout", "fa-triangle-exclamation", "#f39c12", "slide-in-slide-out");
                break;
        }
        return false;
    }

    async get_animation_local(prompt: string, length: number = 0) {
        const { response } = await new Promise<{ response: Response }>((resolve, reject) => {
            console.log(`Generating animation for ${prompt}...`);
            const formData = new FormData();
            formData.append('prompt', prompt);

            formData.append('retarget', 'true');
            if (this._skinnedMesh) {
                formData.append('target_skeleton', this._target_skeleton_data);
            }

            formData.append('seconds', Math.floor(length).toString());

            this.onBusy();

            const model_url = `http://${process.env.MODEL_IP}:${process.env.MODEL_PORT || 8080}/predictions/t2m-model/`;
            console.log(`Using model url ${model_url}...`);
            fetch(model_url, {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    this.onDone();
                    if (this.handle_error(response.status)) {
                        resolve({ response });
                    }
                })
                .catch(error => {
                    this.onDone();
                    displayToastNotification("Communication error!", "fa-xmark", "#c0392b", "slide-in-slide-out");
                    console.error('An error occurred:', error);
                    reject(error);
                });
        });
        return response.text();
    }

    // TODO: Implement this
    async get_animation_T2MService(prompt: string, length: number = 0, version: string = "/api/") {
        const { response } = await new Promise<{ response: Response }>((resolve, reject) => {
            console.log(`Generating animation for ${prompt}...`);

            let request_data = JSON.stringify({
                "prompt": prompt,
                "retarget": true,
                "target_skeleton": JSON.parse(this._target_skeleton_data),
                "seconds": Math.floor(length)
            });

            this.onBusy();

            fetch(`${this.apiEndpoint}${version}generate`, {
                method: 'POST',
                headers: {
                    "x-apikey": `${this.apiKey}`,
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: request_data
            })
                .then(response => {
                    this.onDone();
                    if (this.handle_error(response.status)) {
                        resolve({ response });
                    }
                })
                .catch(error => {
                    this.onDone();
                    displayToastNotification("Error communicating with the service", "fa-xmark", "#c0392b", "slide-in-slide-out");

                    console.error('An error occurred:', error);
                    reject(error);
                    displayToastNotification("Loaded!", "fa-info", "#27ae60", "slide-in-slide-out");
                });
        });
        const text = await response.text();
        const json = JSON.parse(text);
        return json.result;
    }

    async generateAnimation(prompt: string, length: number, version: string = "/api/"): Promise<THREE.AnimationAction> {
        const animation = this.apiKey && this.apiEndpoint ?
            await this.get_animation_T2MService(prompt, length, version) :
            await this.get_animation_local(prompt, length);

        const clip = new JsonAnimationLoader().parse(animation);
        const action = this._mixer!.clipAction(clip);
        return action;
    }

    // async animate(prompt: string) {
    //     if (!this._mixer || !this._skinnedMesh) {
    //         console.warn('No mixer or skinned mesh found, nothing to do.');
    //         return;
    //     }

    //     const animation = process.env.URL && process.env.API_KEY ?
    //         await this.get_animation_GCP(prompt) :
    //         await this.call_model(prompt);

    //     this._clip = new JsonAnimationLoader().parse(animation);

    //     if (this._animation) {
    //         this._animation.stop();
    //         this._animation = null;
    //     }
    //     this._animation = this._mixer.clipAction(this._clip);
    //     this._prompt = prompt;

    //     if (this.isDebugMode()) {
    //         // Dump the JSON animation data
    //         const retargeted_animation = this.dump_animation_data(this._animation.getClip());
    //         this.downloadJSON(retargeted_animation, `retargeted_anim_${this._prompt ?? ""}_${this._modelName}.json`);

    //         // Also dump the BVH file
    //         // const skeleton = this.createSkeletonFromData(JSON.parse(this._target_skeleton_data));
    //         const bvh_exporter = new BVHExporter(this._skinnedMesh.skeleton, this._clip);
    //         const bvh = bvh_exporter.export();
    //         this.downloadText(bvh, `retargeted_anim_${this._prompt ?? ""}_${this._modelName}.bvh`);
    //     }

    //     this._animation.play();
    // }

    updateMatrixWorld(force?: boolean | undefined): void {
        super.updateMatrixWorld(force);

        const delta = this._clock.getDelta();
        if (this._sequencer.playing) {
            this._time += delta;
            const animation = this._sequencer.animations[this._sequencer.currentTrack].action?.getClip();
            if (animation && this._mixer) {
                const duration = animation.duration;
                if (this._time > duration) {
                    const nextAnimationIndex = (this._sequencer.currentTrack + 1) % this._sequencer.animations.length;

                    if ((nextAnimationIndex != this._sequencer.currentTrack) &&
                        (this._sequencer.animations[nextAnimationIndex].action)) {
                        this._sequencer.animations[this._sequencer.currentTrack].action?.stop();
                        this._sequencer.currentTrack = nextAnimationIndex;
                        const action = this._sequencer.animations[this._sequencer.currentTrack].action!;
                        if (action.paused)
                            action.paused = false;
                        action.play();
                    }

                    this._time = 0;
                }
                this._mixer.update(delta);
                this._mixer.time = this._time;
                this._sequencer.updateTime(this._time);
            }
        }

        if (this._skeleton)
            this._aabb?.box.set(this._skeleton.boundingBox.min, this._skeleton.boundingBox.max);
    }
}

export { Model };