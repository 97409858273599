import React from 'react';

import { ThemeAwareComponent } from './Themeable';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './ThemeButton.scss';

interface ThemeButtonProps {
};

class ThemeButton extends ThemeAwareComponent<ThemeButtonProps> {

  handleToggle = () => {
    this.toggleTheme();
  };

  render() {
    return (
      <div className='themeButton'>
        <div className="toggle-switch">
          <input
            type="checkbox"
            id="switch"
            checked={this.getTheme() === 'dark' ? false : true}
            onChange={this.handleToggle}
            className="toggle-input"
          />
          <label htmlFor="switch" className="toggle-label">
            <span className="toggle-icon">
              {this.getTheme() == "dark" ? <i className="fa-solid fa-moon moon-icon"></i>: <i className="fa-solid fa-sun sun-icon"></i> }
            </span>
          </label>
        </div>
      </div>
    );
  }
}

export default ThemeButton;