// components/NavLinks.tsx
import React from 'react';
import { HashLink } from 'react-router-hash-link';

import './NavLinks.scss';

interface NavLinksProps {
  className?: string;  // Optional className for styling the nav element
}

class NavLinks extends React.Component<NavLinksProps> {
  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <HashLink to="/#Playground" className="Header-nav-item">Playground</HashLink>
        <HashLink to="/#Integrations" className="Header-nav-item">Integrations</HashLink>
        <HashLink to="/#For Developers" className="Header-nav-item">Development</HashLink>
        <HashLink to="/#Contact" className="Header-nav-item">Contact</HashLink>
      </div>
    );
  }
}

export default NavLinks;
