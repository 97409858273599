import * as THREE from 'three';

export class SkeletonHelperExt extends THREE.SkeletonHelper {
    private _axesHelpers: THREE.AxesHelper[] = [];
    private _axes_visible: boolean = false;
    private _boundingBox: THREE.Box3 = new THREE.Box3();
    
    constructor(object: THREE.SkinnedMesh | THREE.Object3D, axesHelperSize: number = 0.1) {
        super(object);
        
        const bones: THREE.Bone[] = [];
        getBoneList(object, bones);
        if (axesHelperSize > 0.0) {
            for (let i = 0; i < bones.length; i++) {
                const helper = new THREE.AxesHelper(axesHelperSize);
                helper.material = new THREE.LineBasicMaterial( { vertexColors: true, depthTest: false, depthWrite: false, toneMapped: false, transparent: false } );
                helper.matrixAutoUpdate = false;
                helper.updateMatrixWorld = () => {}; // Disable it from updating the world matrix, we do it in this class instead
                this._axesHelpers.push(helper);

                this.add(helper);
            }
        }
    }

    updateMatrixWorld(force?: boolean | undefined): void {
        super.updateMatrixWorld(force);

        let min:THREE.Vector3 = new THREE.Vector3( + Infinity, + Infinity, + Infinity );
        let max:THREE.Vector3 = new THREE.Vector3( - Infinity, - Infinity, - Infinity );

        const bones: THREE.Bone[] = [];
        getBoneList(this.root, bones);
		for ( let i = 0, j = 0; i < bones.length; i ++ ) {
			const bone = bones[ i ];

            const helper = this._axesHelpers[i];
            helper.visible = this._axes_visible;
            helper.matrixWorld.copy(bone.matrixWorld);

            min = min.min(bone.getWorldPosition(new THREE.Vector3()));
            max = max.max(bone.getWorldPosition(new THREE.Vector3()));
        }

        this._boundingBox.set(min, max);
    }

    get showAxes() {
        return this._axes_visible;
    }
    set showAxes(value: boolean) {
        this._axes_visible = value;
    }

    get boundingBox() {
        return this._boundingBox;
    }
}

function getBoneList( object: THREE.SkinnedMesh | THREE.Object3D, boneList: THREE.Bone[]) {

    if (object.constructor.name === 'SkinnedMesh') {
        for (let i = 0; i < (object as THREE.SkinnedMesh).skeleton.bones.length; i++) {
            boneList.push((object as THREE.SkinnedMesh).skeleton.bones[i]);
        }
    }

    for (let i = 0; i < object.children.length; i++) {
        getBoneList(object.children[i], boneList);
    }
}