import React, { ReactNode } from 'react';
import { Tile, TileProps } from './Tile';

import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './CarouselTile.scss';

interface CarouselTileProps extends TileProps {
    responsiveConfig?: ResponsiveType;
    title?: string;
    autoPlay?: boolean;
    autoPlaySpeed?: number;
}

class CarouselTile extends Tile<CarouselTileProps> {
    render() {
        const defaultResponsiveConfig = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
            },
            tablet: {
                breakpoint: { max: 1024, min: 768 },
                items: 1
            },
            mobile: {
                breakpoint: { max: 767, min: 350 },
                items: 1
            }
        };

        const { width, height, children, responsiveConfig, autoPlay, autoPlaySpeed } = this.props;
        const style: React.CSSProperties = {
            width: `${width}`,
            height: `${height}`
        };
        return (
            <div className={this.getClassName()} style={style}>
                <Carousel
                    responsive={responsiveConfig ?? defaultResponsiveConfig }
                    autoPlay={autoPlay ? autoPlay : false}
                    autoPlaySpeed={autoPlaySpeed ? autoPlaySpeed : 3000}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    partialVisible={true}
                    renderDotsOutside={true}
                >
                    {React.Children.map(children, (child: ReactNode, index: number) => {
                        return (
                            <div className='slider' key={index}>
                                {child}
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        )
    }
}

export default CarouselTile;