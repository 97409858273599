import * as THREE from 'three';

export class InfiniteGridHelper extends THREE.Mesh {
	constructor(
		size1: number,
		size2: number,
		color: THREE.Color,
		distance: number,
		offset: number,
		axes = 'xzy',
		fallOff = 4
	) {
		color = color || new THREE.Color('white');
		size1 = size1 || 10;
		size2 = size2 || 100;
		distance = distance || 8000;
		offset = offset || 0;

		const planeAxes = axes.substr(0, 2);

		const geometry = new THREE.PlaneGeometry(2, 2, 1, 1);

		const material = new THREE.ShaderMaterial({
			side: THREE.DoubleSide,
			uniforms: {
				uSize1: {
					value: size1,
				},
				uSize2: {
					value: size2,
				},
				uColor: {
					value: color,
				},
				uFallOff: {
					value: fallOff.toFixed(3),
				},
				uDistance: {
					value: distance,
				},
				uOffset: {
					value: offset
				}
			},
			transparent: true,
			vertexShader: `
				varying vec3 worldPosition;
				uniform float uDistance;
				void main() {
					vec3 pos = position.${axes} * uDistance + ${offset.toFixed(3)};
					pos.${planeAxes} += cameraPosition.${planeAxes};
					worldPosition = pos;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
				}
			`,
			fragmentShader: `
				varying vec3 worldPosition;
				uniform float uSize1;
				uniform float uSize2;
				uniform float uFallOff;
				uniform vec3 uColor;
				uniform float uDistance;
				float getGrid(float size) {
					vec2 r = worldPosition.${planeAxes} / size;
					vec2 grid = abs(fract(r - 0.5) - 0.5) / fwidth(r);
					float line = min(grid.x, grid.y);
					return 1.0 - min(line, 1.0);
				}
				void main() {
					float d = 1.0 - min(distance(cameraPosition.${planeAxes}, worldPosition.${planeAxes}) / uDistance, 1.0);
					float g1 = getGrid(uSize1);
					float g2 = getGrid(uSize2);
					gl_FragColor = vec4(uColor.rgb, mix(g2, g1, g1) * pow(d, uFallOff));
					gl_FragColor.a = mix(0.5 * gl_FragColor.a, gl_FragColor.a, g2);
					if (gl_FragColor.a <= 0.0) discard;
				}
			`,
		});

		super(geometry, material);

		this.frustumCulled = false;
	}
}
