// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customButton {
  background-color: var(--button-bg);
  color: var(--button-fg);
  border-radius: 25px;
  border: 1px solid var(--button-border-color);
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  font-family: "Signika Negative", sans-serif, Arial;
  font-size: 1rem !important;
}

.customButton i {
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomButton.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,uBAAA;EACA,mBAAA;EACA,4CAAA;EACA,sBAAA;EACA,eAAA;EACA,kDAAA;EACA,0BAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".customButton {\n    background-color: var(--button-bg);\n    color: var(--button-fg);\n    border-radius: 25px;\n    border: 1px solid var(--button-border-color);\n    padding: .5rem 1.5rem;\n    cursor: pointer;\n    font-family: \"Signika Negative\", sans-serif, Arial;\n    font-size: 1rem!important;\n}\n\n.customButton i {\n    font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
