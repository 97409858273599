import { Component } from "react";

export class ThemeAwareComponent<P = {}, S = {}> extends Component<P, S> {
    private theme: "light" | "dark";

    protected onThemeChanged() {
    }

    protected toggleTheme(){
        this.theme = this.getTheme() == 'light' ? 'dark' : 'light';
        document.body.setAttribute('data-theme', this.getTheme());
        localStorage.setItem('theme', this.getTheme());
        this.forceUpdate();
        this.onThemeChanged();
    }

    protected getTheme() {
        return this.theme;
    }

    constructor (props: P) {
        super(props);

        // Get the system preference
        const local_storage_preference = localStorage.getItem('theme');
        const system_preference = window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark';
        this.theme = local_storage_preference as ('light' | 'dark') ?? system_preference;
        // Set this as the initial state
        document.body.setAttribute('data-theme', this.theme);
        
        // Define a callback function that reacts to changes to system preference
        const mediaQuery = window.matchMedia('(prefers-color-scheme: light)');
        const changeListener = (e: MediaQueryListEvent) => {
            if (e.matches) {
                this.theme = 'light';
                this.onThemeChanged();
            } else {
                this.theme = 'dark';
                this.onThemeChanged();
            }
        };
        // Add the listener to the media query
        mediaQuery.addEventListener('change', changeListener);

        // Also setup a listener for changes in data-theme
        // MutationObserver to observe changes in data-theme attribute
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
                    const newTheme = document.body.getAttribute('data-theme') as "light" | "dark";
                    if (newTheme !== this.theme) {
                        this.theme = newTheme;
                        this.onThemeChanged();
                    }
                }
            });
        });
        // Configuration of the observer:
        const config = { attributes: true, attributeFilter: ['data-theme'] };

        // Start observing the target node for configured mutations
        observer.observe(document.body, config);        
    }

    override componentDidMount(): void {
        this.theme = this.getTheme();
        document.body.setAttribute('data-theme', this.getTheme());
        localStorage.setItem('theme', this.getTheme());
        this.forceUpdate();
        this.onThemeChanged();
    }
}