import React, { Component } from 'react';
import './Badge.scss'; // Import the SCSS file

// Define prop types
interface CornerPositionProps {
  position: 'top-left' | 'top-right'; // Only allow these two values
  children: React.ReactNode; // Accepts any JSX/HTML content as children
}

class Badge extends Component<CornerPositionProps> {
  // Function to dynamically set the class based on the position prop
  getPositionClass(): string {
    const { position } = this.props;
    let className = "badge__container positionWrapper"
    // Return the correct class name based on the position prop
    if (position === 'top-left') {
        className = className + ' topLeft';
        return className;
    } else if (position === 'top-right') {
        className = className + ' topRight';
        return className ;
    }
    return className; // Default to just the wrapper if position is somehow missing
  }

  render() {
    return (
      <div className={this.getPositionClass()}>
        {this.props.children}
      </div>
    );
  }
}

export default Badge;