import React, { ReactNode } from 'react';
import { Tile, TileProps } from './Tile';

import Carousel, { ResponsiveType } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './TileSet.scss';

interface TileSetProps extends TileProps {
    responsiveConfig?: ResponsiveType;
    title?: string;
    showNavButtons?: string;
    navButtonPos?:  "top center" | "top left" | "top right"  | "bottom center" | "bottom left" | "bottom right" | "";
    autoplay?: boolean;
    autoplaySpeed?: number;
}

class TileSet extends Tile<TileSetProps> {

    static defaultProps = {
        showNavButtons: 'false',
        navButtonPos: ''
    };

    constructor(props: TileSetProps) {
        super(props);
        this.state = {
            showNavButtons: props.showNavButtons || TileSet.defaultProps.showNavButtons!,
            navButtonPos: props.navButtonPos || TileSet.defaultProps.navButtonPos!
        }
    }

    render() {
        const defaultResponsiveConfig = {
            desktop: {
                breakpoint: { max: 3000, min: 1436 },
                items: 5,
                slidesToSlide: 0,
            },
            tablet: {
                breakpoint: { max: 1435, min: 768 },
                items: 3,
                slidesToSlide: 0
            },
            mobile: {
                breakpoint: { max: 767, min: 350 },
                items: 1,
                slidesToSlide: 0
            }
        };

        const { width, height, showNavButtons, 
                navButtonPos,  children, responsiveConfig,
                autoplay, autoplaySpeed } = this.props;
        const style: React.CSSProperties = {
            width: `${width}`,
            height: `${height}`
        };
        const navBtnClassName = showNavButtons=='true' ? '' : 'hide-buttons';
        const navBtnPosName = navButtonPos;
        const componentClassName = this.getClassName() + " " + navBtnClassName + " " + navBtnPosName;
        return (
            <div id={this.props.title} className={componentClassName} style={style}>
                {this.props.title ? <span className='tile-title'>{this.props.title}</span> : null}
                <Carousel
                    responsive={responsiveConfig ?? defaultResponsiveConfig }
                    autoPlay={autoplay ?? true}
                    autoPlaySpeed={autoplaySpeed ?? 5000}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    infinite={true}
                    partialVisible={false}
                    renderDotsOutside={false}
                >
                    {React.Children.map(children, (child: ReactNode, index: number) => {
                        return (
                            <div className='multi-tile-slider' key={index}>
                                {child}
                            </div>
                        )
                    })}
                </Carousel>
            </div>
        )
    }
}

export default TileSet;