// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --tile-bg: #ffffff;
  --tile-fg: #000000;
  --tile-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  --background-blur: 7.76px;
}

[data-theme=light] {
  --tile-bg: #aaaaaa;
  --tile-fg: #000000;
  --tile-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  --tile-bg-blur: rgba(0, 0, 0, 0.10);
}

[data-theme=dark] {
  --tile-bg: #666666;
  --tile-fg: #ffffff;
  --tile-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  --tile-bg-blur: rgba(0, 0, 0, 0.29);
}

.debug {
  border: 1px solid red;
  background-color: aqua;
}

.Tile {
  background-color: var(--tile-bg);
  opacity: 1;
  border-radius: 20px;
  height: 100px;
  margin: 5px;
  width: 100px;
  transition: transform var(--transition-time) ease;
  z-index: 1;
  backdrop-filter: var(--background-blur);
  background: var(--tile-bg-blur);
}`, "",{"version":3,"sources":["webpack://./src/components/Tile.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,kBAAA;EACA,yCAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,kBAAA;EACA,yCAAA;EACA,mCAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,kBAAA;EACA,+CAAA;EACA,mCAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,gCAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,iDAAA;EACA,UAAA;EACA,uCAAA;EACA,+BAAA;AAAJ","sourcesContent":["@import url('../index.scss');\n\n:root { \n    --tile-bg: #ffffff;\n    --tile-fg: #000000;\n    --tile-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n    --background-blur: 7.76px;\n}\n\n[data-theme=\"light\"] {\n    --tile-bg: #aaaaaa;\n    --tile-fg: #000000;\n    --tile-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n    --tile-bg-blur: rgba(0, 0, 0, 0.10);\n}  \n\n[data-theme=\"dark\"] {\n    --tile-bg: #666666;\n    --tile-fg: #ffffff;\n    --tile-shadow: 0 0 5px rgba(255, 255, 255, 0.3);\n    --tile-bg-blur: rgba(0, 0, 0, 0.29);\n}  \n\n.debug {\n    border: 1px solid red;\n    background-color: aqua;\n}\n\n.Tile {\n    background-color: var(--tile-bg);\n    opacity: 1;\n    border-radius: 20px;\n    height: 100px;\n    margin: 5px;\n    width: 100px;\n    transition: transform var(--transition-time) ease;\n    z-index: 1;\n    backdrop-filter: var(--background-blur);\n    background: var(--tile-bg-blur);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
