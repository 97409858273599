// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.themeButton {
  margin-left: auto;
  padding-top: 3px;
}
.themeButton .toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
}
.themeButton .toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}
.themeButton .toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border: 1px solid var(--color-fg);
  border-radius: 34px;
}
.themeButton .sun-icon {
  position: absolute;
  left: 2px;
  opacity: 1;
  top: 1px;
}
.themeButton .moon-icon {
  position: absolute;
  left: 24px;
  opacity: 1;
  top: 1px;
}
.themeButton .toggle-input + .toggle-label .moon-icon {
  opacity: 1;
  transition: 0.4s;
}
.themeButton .toggle-input + .toggle-label .sun-icon {
  opacity: 1;
  transition: 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/components/ThemeButton.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAEE;EACE,UAAA;EACA,QAAA;EACA,SAAA;AAAJ;AAGE;EACE,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,gBAAA;EACA,iCAAA;EACA,mBAAA;AADJ;AAIE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,QAAA;AAFJ;AAKE;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,QAAA;AAHJ;AAME;EACE,UAAA;EACA,gBAAA;AAJJ;AAOE;EACE,UAAA;EACA,gBAAA;AALJ","sourcesContent":[".themeButton {\n  margin-left: auto;\n  padding-top: 3px;\n\n  .toggle-switch {\n    position: relative;\n    display: inline-block;\n    width: 44px;\n    height: 20px;\n  }\n\n  .toggle-input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n  }\n\n  .toggle-label {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    transition: .4s;\n    border: 1px solid var(--color-fg);\n    border-radius: 34px;\n  }\n\n  .sun-icon {\n    position: absolute;\n    left: 2px;\n    opacity: 1;\n    top: 1px;\n  }\n\n  .moon-icon {\n    position: absolute;\n    left: 24px;\n    opacity: 1;\n    top: 1px;\n  }\n\n  .toggle-input + .toggle-label .moon-icon {\n    opacity: 1;\n    transition: .4s;\n  }\n\n  .toggle-input + .toggle-label .sun-icon {\n    opacity: 1;\n    transition: .4s;\n  }\n}  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
