import React, { Component, ReactNode } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './CustomButton.scss';
import { ThemeAwareComponent } from './Themeable';
import { HashLink } from 'react-router-hash-link';

interface CustomButtonProps {
    onClick?: () => void;
    label?: string;
    href?: string;
    link?: string;
    inNewTab?: boolean;
    style?: React.CSSProperties;
    children?: ReactNode;
    className?: string;
}
  
  class CustomButton extends ThemeAwareComponent<CustomButtonProps> {
    render() {
      let { onClick, label, style, children, className, href, link, inNewTab } = this.props;
      inNewTab = inNewTab ?? true;

      if (href) {
        return (
            <a
                href={href}
                target={inNewTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
                style={style ?? {color:"#fff"}}
                className={`customButton ${className}`}
            >
                {label} {children}
            </a>
        );
      }

      if (link) {
        return (
            <HashLink
                to={link}
                style={style ?? {color:"#fff"}}
                className={`customButton ${className}`}
            >
                {label} {children}
            </HashLink>
        );
      }
      return (
        <button style={style ?? {}} className={`customButton ${className}`} onClick={onClick}>
          {label} {children}
        </button>
      );
    }
  }
  
  export default CustomButton;