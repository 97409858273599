import React, { createRef } from 'react';
import { Tile, TileProps } from './Tile';

import './PlaygroundTile.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CustomButton from './CustomButton';
import CookieContext from './CookieContext';
import LoginButton from './LoginButton';

export interface PlaygroundTileProps extends TileProps {
    expandedWidth: string;
    expandedHeight: string;
}

interface PlaygroundTileState {
    expanded: boolean;
    buttonVisible?: boolean;
    expandDone?: boolean;
}

export class PlaygroundTile extends Tile<PlaygroundTileProps> {
    state: PlaygroundTileState = { expanded: false, buttonVisible: true };
    tileRef = createRef<HTMLDivElement>();

     // Specify the context type
     static contextType = CookieContext;
     context!: React.ContextType<typeof CookieContext>;

    // Get the value of the CSS variable transition-time
    getTransitionTimeSeconds(): number {
        const root = document.documentElement;
        const style = getComputedStyle(root);
        // remove the s suffix if any
        const transitionTime = style.getPropertyValue('--transition-time').replace('s', '');
        return parseFloat(transitionTime);
    }

    smoothScroll(elem: Element, options: ScrollIntoViewOptions): Promise<void> {
        return new Promise<void>((resolve) => {
            if (!(elem instanceof Element)) {
                throw new TypeError('Argument 1 must be an Element');
            }
            let same = 0; // a counter
            let lastPos: number | null = null; // last known Y position
            // pass the user defined options along with our default
            const scrollOptions = Object.assign({ behavior: 'smooth' }, options);

            // let's begin
            elem.scrollIntoView(scrollOptions);
            requestAnimationFrame(check);

            // this function will be called every painting frame
            // for the duration of the smooth scroll operation
            function check() {
                // check our current position
                const newPos = elem.getBoundingClientRect().top;

                if (newPos === lastPos) { // same as previous
                    if (same++ > 2) { // if it's more than two frames
                        /* @todo: verify it succeeded
                        * if(isAtCorrectPosition(elem, options) {
                        *   resolve();
                        * } else {
                        *   reject();
                        * }
                        * return;
                        */
                        return resolve(); // we've come to a halt
                    }
                }
                else {
                    same = 0; // reset our counter
                    lastPos = newPos; // remember our current position
                }
                // check again next painting frame
                requestAnimationFrame(check);
            }
        });
    }

    toggleExpanded = () => {
        if(!this.context?.isLoggedIn()) {
            return;
        }
        let { expandDone } = this.state
        if (expandDone)
            return;
        if (this.state.buttonVisible)
            this.setState({ buttonVisible: false });

        const playgroundElement = document.getElementById('MyThree');
        const disclaimerElement = document.getElementById('disclaimer');

        this.setState((prevState: PlaygroundTileState) => ({ expanded: !prevState.expanded }), () => {

            if (playgroundElement && !this.state.expanded)
                playgroundElement.style.zIndex = '-1';

            // TODO: Prevent scrolling when the tile is expanded
            // See here: https://stackoverflow.com/a/57867348/802203
            setTimeout(async () => {
                if (this.state.expanded && this.tileRef.current) {
                    const target = this.tileRef.current;
                    // prevent this page from scrolling when the tile is expanded
                    document.body.style.overflow = 'hidden';
                    
                    await this.smoothScroll(target, { behavior: 'smooth', block: 'center' });
                    this.setState({ expandDone: true });
                    this.setState({ closeButtonVisible: true });
                    document.body.classList.add("hidePanel");
                    document.querySelector(".PlaygroundTile")?.scrollIntoView();
                    if (playgroundElement)
                        playgroundElement.style.zIndex = '0';

                    // Show disclaimer
                    if (disclaimerElement) {
                        disclaimerElement.style.display = 'block';
                    }
                }
                else {
                    document.body.classList.remove("hidePanel");
                    this.setState({ buttonVisible: true });
                    this.setState({ closeButtonVisible: false });
                    // allow this page to scroll when the tile is collapsed
                    document.body.style.overflow = 'auto';
                    //This will move the scroll to Playground Tile after removing hidePanel class from body
                    document.querySelector(".PlaygroundTile")?.scrollIntoView();

                    // Hide disclaimer
                    if (disclaimerElement)
                        disclaimerElement.style.display = 'none';
                }
            }, this.getTransitionTimeSeconds() * 1000);
        });
    }

    closeExpanded = async () => {
        // This is needed because setting state is asynchronous and we want to wait
        await this.setState({ expandDone: false });
        // before calling toggleExpanded
        this.toggleExpanded();
    }
    
    render() {
        const { id, width, height, expandedWidth, expandedHeight } = this.props;
        const { expanded, buttonVisible } = this.state;
        const style: React.CSSProperties = {
            width: expanded ? expandedWidth : width,
            height: expanded ? expandedHeight : height,
            display: 'flex',
            position: "relative",
            pointerEvents: expanded ? 'none' : 'auto',
            transition: `all var(--transition-time) ease-in-out`,
            backdropFilter: expanded ? 'none' : 'var(--blur-background)',
            WebkitBackdropFilter: expanded ? 'none' : 'var(--blur-background)',
            alignItems: 'center',
            justifyContent: 'center',
        };

        const buttonStyle: React.CSSProperties = {
            position: "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        };

        const closeButtonStyle: React.CSSProperties = {
            position: "fixed",
            top: '3%',
            left: '2%',
            padding: '0px',
            height: '25px',
            width: '25px',
            backgroundColor: 'rgba(255,255,255, 0.4)',
            pointerEvents: expanded ? 'auto' : 'none',
            color: this.getTheme() === 'light' ? 'var(--button-bg)' : 'var(--color-fg)',
        };

        if (!this.context) {
            return <div>Error: No context found</div>;
        }

        return (
            <div id={id ?? this.getClassName()} className={this.getClassName()} onClick={this.toggleExpanded} ref={this.tileRef}>
                <div style={style} data-login={!!this.context?.isLoggedIn()}>
                    { !!this.context?.isLoggedIn() ? (
                            buttonVisible && <CustomButton style={buttonStyle} label = "Click to try our playground" />
                        ) : (
                            buttonVisible && <LoginButton text='Login to try our playground'/>
                        )
                    }
                    {!buttonVisible && <CustomButton className='close-icon' style={closeButtonStyle} onClick={this.closeExpanded}><i className="fa-solid fa-xmark"></i></CustomButton>}
                </div>
                <div id="disclaimer" className='disclaimer'>
                    <p>
                        <b>Disclaimer</b>: This AI preview assists with content creation, but as our dataset is growing, errors may occur; review all outputs for accuracy & compliance, as we are not liable for any consequences. By using this playground you agree to our <a className='link' href="/tos.html" target='_blank'>Terms and Conditions</a> and <a className='link' href="/privacy.html" target='_blank'>Privacy Policy</a>.
                        <br/>
                        Animations provided under <a className='link' href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC-by-4.0</a> <b>© Text2Motion Inc., 2024. All rights reserved.</b> 
                    </p>
                </div>
            </div>
        );
    }
}