// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --blur-background: blur(10px);
  --padding: 10px;
  --offset: 20vw;
}

.TileManager {
  z-index: 0;
  /* From https://stackoverflow.com/a/5219768/802203
  to size the div + padding to the width of the parent div */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1em;
}

.TileManager span {
  color: var(--color-fg);
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-style: normal;
  display: block;
  font-weight: 700;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/TileManager.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,eAAA;EACA,cAAA;AACJ;;AACA;EACI,UAAA;EAEA;4DAAA;EAEA,8BAAA;EACA,2BAAA;EACA,sBAAA;EACA,YAAA;AACJ;;AAEA;EACI,sBAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[":root {\n    --blur-background: blur(10px);\n    --padding: 10px;\n    --offset: 20vw;\n}\n.TileManager {\n    z-index: 0;\n\n    /* From https://stackoverflow.com/a/5219768/802203\n    to size the div + padding to the width of the parent div */\n    -webkit-box-sizing: border-box;\n    -moz-box-sizing: border-box;\n    box-sizing: border-box;\n    padding: 1em;\n}\n\n.TileManager span {\n    color: var(--color-fg);\n    font-family: 'Montserrat', sans-serif;\n    font-size: 2rem;\n    font-style: normal;\n    display: block;\n    font-weight: 700;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
